.App {
  min-height: 100vh;
}

.App,
.editor {
  display: flex;
  flex-direction: column;
  justify-content: top;
  margin-left: min(10vmin, 5em);
  margin-right: min(10vmin, 5em);
}

.document {
  text-align: center;
  font-size: calc(10px + 2vmin);
  align-items: center;
}

.frontpage {
  text-align: left;
  font-size: calc(10px + 1vmin);
  align-items: left;
  max-width: 200mm;
  margin: auto;
}
.frontpage figure {
  margin: 3ex auto;
}
.frontpage figure img {
  width: 100%;
}
.frontpage figcaption {
  font-style: italic;
  margin: 0 min(10vw, 20mm);
}

.editor {
  padding: 2vh 1vw;
}

.question,
.createquestion,
.option,
.createoption {
  text-align: left;
}

th.result div,
th.person div {
  margin: auto;
}

.result,
.vote {
  text-align: right;
}

th.question div,
th.option div,
th.result div,
th.person div {
  writing-mode: vertical-rl;
}

th.person {
  font-weight: normal;
}

.question,
.result {
  font-weight: bold;
}

.evencol {
  background-color: #ddd;
}

.me {
  background-color: #ff9;
}

table {
  border-collapse: collapse;
  margin-left: 4vw;
}

th,
td {
  vertical-align: top;
  padding: 0.4vh 0.8vw;
}
th.createperson {
  padding: 0 0.8vw;
}
td.createquestion,
td.createoption {
  padding: 0.4vh 0;
}

th.question,
th.option,
th.result,
th.person,
td.option,
td.result,
td.vote {
  border: 1px solid #888;
}

.MuiTooltip-tooltipPlacementLeft,
.MuiTooltip-tooltipPlacementRight {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.MuiTooltip-tooltipPlacementTop,
.MuiTooltip-tooltipPlacementBottom {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.MuiTooltip-tooltip {
  white-space: pre-wrap;
}

.voteRoot {
  font-size: calc(8px + 1vmin);
  min-width: calc(5ex+max(20vw, 20ex));
}
.voteInput {
  margin-top: 2ex;
}
.voteInputbox {
  text-align: right;
  width: 4em !important;
}
.voteSlider {
  min-height: 10em;
  width: calc(max(20vw, 20ex));
  padding: 0 2ex;
}
.voteMark {
  writing-mode: vertical-rl;
  font-size: calc(8px + 1vmin) !important;
}
